import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteItems, updateStateData } from "../../../Store/actions";
import { filterCampaigns } from "../../../api_calls/campaignRequests";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import { Box, Stack } from "@mui/material";
import { StyledStack } from "../../../components/commonComponents/StyledComponents/styled";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { FilterAlt } from "@mui/icons-material";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";

const CampaignListFilters = (props) => {
  const Campaigns = useSelector((state) => state.userSelections);
  const [campaignNameFilter, setCampaignNameFilter] = React.useState([]);
  const [campaignDurationFilter, setCampaignDurationFilter] = React.useState([]);

  const [campaignNameOptionsSelected, setCampaignNameOptionsSelected] = React.useState([]);
  const [campaignDurationOptionsSelected, setCampaignDurationOptionsSelected] = React.useState([]);

  const [retailerFilter, setRetailerFilter] = React.useState([]);
  const [assignedToFilter, setAssignedToFilter] = React.useState([]);
  const [statusFilter, setStatusFilter] = React.useState([]);
  const [startDateFilter, setStartDateFilter] = React.useState("");
  const [endDateFilter, setEndDateFilter] = React.useState("");
  const durationFilterOptions = [
    {
      value: 1,
      label: "Active",
    },
    {
      value: 2,
      label: "Scheduled",
    },
    {
      value: 3,
      label: "Completed",
    },
  ];
  const t = useCustomTranslation();

  const dispatchAction = useDispatch();

  const saveCampaignFilters = (e) => {
    e.preventDefault();
    if (
      campaignNameFilter.length === 0 &&
      campaignDurationFilter.length === 0 &&
      retailerFilter.length === 0 &&
      assignedToFilter.length === 0 &&
      statusFilter.length === 0 &&
      startDateFilter.length === 0 &&
      endDateFilter.length === 0
    ) {
      // toast("Please select filters before applying.", { type: "error" });
      clearfilters();
    } else {
      const filterKeywords = [
        "campaign_name",
        "retailer_filter",
        "assigned_to_filter",
        "status_filter",
        "startdate_filter",
        "enddate_filter",
        "duration_filter",
      ];
      const selectedFilters = [
        campaignNameFilter,
        retailerFilter,
        assignedToFilter,
        statusFilter,
        startDateFilter,
        endDateFilter,
        campaignDurationFilter,
      ];
      let filterValues = {};
      /*
        There could be a simple straight forward way of adding values to the redux state.
        we have used map function here just to iterate through each record. Because otherwise,
        we get some error in browser console when we try to update a particular index in an array.
        However, when we use loop/map function then it doesn't show any error. 
        May be in future, we can figure out a better way to do this. But for time being, it's been
        implemented this way.
      */
      filterKeywords.map((keyword, index) => {
        filterValues = {
          ...filterValues,
          [keyword]: typeof selectedFilters[index] === "string" ? selectedFilters[index] : [...selectedFilters[index]],
        };
      });

      //save all selected filters to redux state (it will be accessed for generating powerpoint later)
      dispatchAction(
        updateStateData({
          campaign_list_filters: filterValues,
        })
      );
      // filter records according to the selection
      filterCampaigns();
    }
  };

  const clearfilters = () => {
    setCampaignNameFilter([]);
    setRetailerFilter([]);
    setAssignedToFilter([]);
    setStatusFilter([]);
    setStartDateFilter("");
    setEndDateFilter("");
    //reset campaign listing
    dispatchAction(deleteItems(["campaign_list_filters", "filtered_campaign_list", "campaign_list_filter_applied"]));
  };

  return (
    typeof Campaigns.campaign_list !== "undefined" && (
      <>
        {/* ----------- filter row 1 -------- */}
        <Box>
          <Stack direction={"row"} spacing={2}>
            {/* ------- campaign name filters --------- */}
            <MultiSelect
              enableFullWidth={false}
              label={"Campaign Name"}
              options={Campaigns.campaign_list.campaign_name_obj}
              defaultVal={campaignNameOptionsSelected}
              selectionCheck={(option, value) => option.id === value.id}
              usedFor="filter"
              onChangeCall={(selectedOption) => {
                let userChoices = [];
                selectedOption.forEach((chosen) => {
                  userChoices.push(chosen.id);
                });
                setCampaignNameFilter(userChoices);
                setCampaignNameOptionsSelected(selectedOption);
              }}
            />
            {/* ------------ campaign duration status filter ------------- */}
            <MultiSelect
              label={"Campaign Progress"}
              elemId={"campaignProgressFilterOptions"}
              enableFullWidth={false}
              options={durationFilterOptions}
              usedFor="filter"
              defaultVal={campaignDurationOptionsSelected}
              selectionCheck={(option, value) => option.value === value.value}
              onChangeCall={(selectedOption) => {
                let userChoices = [];
                selectedOption.forEach((chosen) => {
                  userChoices.push(chosen.label);
                });
                setCampaignDurationFilter(userChoices);
                setCampaignDurationOptionsSelected(selectedOption);
              }}
            />

            {/* ----------- retailer filter -------- */}
            {/* <MultiSelectorDropDown
              classDiv="col-md-2"
              selectorLabel={t("partner_label")}
              selectorId="partnerNameInput"
              defaultVal={[]}
              selectorOptions={Campaigns.campaign_list.retailer_keywords.map((retailer) => {
                return {
                  value: retailer,
                  label: retailer,
                };
              })}
              onChangeCall={(option, { action }) => {
                let userChoices = [];
                option.forEach((chosen) => {
                  userChoices.push(chosen.value);
                });
                setRetailerFilter(userChoices);
              }}
              error_detail=""
            /> */}

            {/* ----------- Assigned to filters ------------ */}
            {/* <MultiSelectorDropDown
              classDiv="col-md-2"
              selectorLabel={t("assigned_to_label")}
              selectorId="assignedReporterInput"
              defaultVal={[]}
              selectorOptions={platformConfig.assignee_values}
              onChangeCall={(option, { action }) => {
                let userChoices = [];
                option.forEach((chosen) => {
                  userChoices.push(chosen.value);
                });
                setAssignedToFilter(userChoices);
              }}
              error_detail=""
            /> */}

            {/* ---------- status filter --------- */}
            {/* <MultiSelect
              label={t("campaign_status_label")}
              options={[
                { value: "0", label: platformConfig.campaign_status_codes[0].name },
                { value: "1", label: platformConfig.campaign_status_codes[1].name },
                { value: "2", label: platformConfig.campaign_status_codes[2].name },
                { value: "3", label: platformConfig.campaign_status_codes[3].name },
                { value: "4", label: platformConfig.campaign_status_codes[4].name },
                { value: "5", label: platformConfig.campaign_status_codes[5].name },
              ]}
              defaultVal={
                campaignDurationFilter.length !== 0
                  ? campaignDurationFilter.map((duration) => {
                      return {
                        value: duration,
                        label: duration,
                      };
                    })
                  : []
              }
              onChangeCall={(selectedOption) => {
                let userChoices = [];
                selectedOption.forEach((chosen) => {
                  userChoices.push(chosen.value);
                });
                setStatusFilter(userChoices);
              }}
            /> */}

            {/* ---------- start date filter ------- */}
            {/* <DateInputField
              fieldRequired={true}
              elemId="startDate"
              label={t("start_date_label")}
              fieldName="start_date"
              defaultVal={startDateFilter}
              showlabel={false}
              onChangeCall={(selectedDate) => {
                setStartDateFilter(selectedDate);
              }}
            /> */}

            {/* ---------- end date filter ------- */}
            {/* <DateInputField
              fieldRequired={true}
              elemId="endDateFilter"
              label={t("end_date_label")}
              fieldName="endDateFilter"
              defaultVal={endDateFilter}
              showlabel={false}
              onChangeCall={(selectedDate) => {
                setEndDateFilter(selectedDate);
              }}
            /> */}
            <StyledStack direction={"row"} spacing={2}>
              <ActionButton onClick={saveCampaignFilters} label={t("apply_filter_button_text")} icon={<FilterAlt />} />
              {/* <LightGreyButton
                onClick={(e) => {
                  e.preventDefault();
                  clearfilters();
                }}
                label={t("clear_filter_button_text")}
                icon={<Clear />}
              /> */}
            </StyledStack>
          </Stack>
          <Box height={20} />
        </Box>
      </>
    )
  );
};

export default CampaignListFilters;
