import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Campaigns from "../pages/Campaigns/Campaigns";
import GeneralKpi from "../pages/Dashboards/GeneralKpi";
import InstoreDuration from "../pages/Dashboards/InstoreDuration";
import Profitability from "../pages/Dashboards/Profitability";
import TrolleyUsage from "../pages/Dashboards/TrolleyUsage";
import Duration from "../pages/Dashboards/Duration";
import HeatMap from "../pages/Dashboards/HeatMap";
import Optimization from "../pages/Dashboards/Optimization";
import Overview from "../pages/Overview/Overview";
import Clips from "../pages/Campaigns/Clips";
import Contacts from "../pages/Campaigns/Contacts";
import Companies from "../pages/Campaigns/Companies";
import Pictures from "../pages/Campaigns/Pictures";
import Locations from "../pages/Infrastructure/Locations";
import Monitoring from "../pages/Infrastructure/Monitoring";
import BrandAnalytics from "../pages/Dashboards/BrandAnalytics";
import CampaignAnalytics from "../pages/Campaigns/CampaignAnalytics";
import Login from "../pages/Login/Login";
import ConfigureLocation from "../pages/Infrastructure/Dashboards/ConfigureLocation";
import Users from "../pages/userManagement/Users";
import Roles from "../pages/userManagement/Roles";
import { fetchToken } from "../api_calls/requestConfig";
import ForgotPassword from "../pages/Login/ForgotPassword";
import NotFound from "../pages/NotFound/NotFound";
import BrandReportView from "../pages/Campaigns/BrandReportView";

const AuthenticatedRoute = ({ children }) => {
  const isAuthenticated = fetchToken();
  return isAuthenticated !== "" ? children : <Navigate to="/" />;
};

const WebRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/cap-overview"
          element={
            <AuthenticatedRoute>
              <Overview />
            </AuthenticatedRoute>
          }
        />
        {/* <Route path="/check-campaigns" element={<Campaigns />} /> */}
        <Route
          path="/check-campaigns/list"
          element={
            <AuthenticatedRoute>
              <Campaigns />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/check-campaigns/clips"
          element={
            <AuthenticatedRoute>
              <Clips />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/check-campaigns/Contacts"
          element={
            <AuthenticatedRoute>
              <Contacts />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/check-campaigns/Companies"
          element={
            <AuthenticatedRoute>
              <Companies />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/check-campaigns/pictures"
          element={
            <AuthenticatedRoute>
              <Pictures />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/check-campaigns/dashboard"
          element={
            <AuthenticatedRoute>
              <BrandAnalytics />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/check-campaigns/campaign-analytics/:id"
          element={
            <AuthenticatedRoute>
              <CampaignAnalytics />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/check-campaigns/brand-report-view/:id"
          element={
            <AuthenticatedRoute>
              <BrandReportView />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/analytics/general-kpi"
          element={
            <AuthenticatedRoute>
              <GeneralKpi />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/analytics/instore-duration"
          element={
            <AuthenticatedRoute>
              <InstoreDuration />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/analytics/profitability"
          element={
            <AuthenticatedRoute>
              <Profitability />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/analytics/trolley-usage"
          element={
            <AuthenticatedRoute>
              <TrolleyUsage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/analytics/duration"
          element={
            <AuthenticatedRoute>
              <Duration />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/analytics/heat-map"
          element={
            <AuthenticatedRoute>
              <HeatMap />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/analytics/optimization"
          element={
            <AuthenticatedRoute>
              <Optimization />
            </AuthenticatedRoute>
          }
        />

        {/* <Route path="/infrastructure" element={<Infrastructure />} /> */}
        <Route
          path="/infrastructure/locations"
          element={
            <AuthenticatedRoute>
              <Locations />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/infrastructure/monitoring"
          element={
            <AuthenticatedRoute>
              <Monitoring />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/infrastructure/configure-location/:id"
          element={
            <AuthenticatedRoute>
              <ConfigureLocation />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/users/list"
          element={
            <AuthenticatedRoute>
              <Users />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/users/role"
          element={
            <AuthenticatedRoute>
              <Roles />
            </AuthenticatedRoute>
          }
        />
        {/* 404 Not Found route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default WebRoutes;
