/*
    This file has all the functions used in the campaign sheet feature.
*/
import { updateStateData, deleteItems } from "../Store/actions";
import store from "../Store/store";

export const restructure_campaign_sheet_data = (campaignData, characterLength = 10, campaign_id, retailer) => {
  let restructuredData = [];
  let filterKeywords = [];
  let refactoredKeywords = [];
  //traverse all the records from campaignData
  campaignData.forEach((campaign) => {
    if (campaign.description !== "") {
      //create a keyword using initials from the description
      const productKeyword = campaign.description.substring(0, characterLength);
      campaign = {
        ...campaign,
        description: replaceUmlaouts(campaign.description),
        keyword: productKeyword,
        campaign: campaign_id,
        retailer: retailer,
      };
      restructuredData.push(campaign);
      filterKeywords.push(productKeyword);
    }
  });

  //need to refactor filter keyword to fit react-select module
  removewithfilter(filterKeywords).forEach((keyword) => {
    if (keyword !== "") {
      refactoredKeywords.push({ value: keyword, label: keyword });
    }
  });

  //save to redux
  store.dispatch(
    updateStateData({
      campaign_sheet_records: restructuredData,
      sheet_filter_keywords: refactoredKeywords,
    })
  );

  return {
    restructuredData,
    filterKeywords: refactoredKeywords,
  };
};

//to return only the unique elements from an array
const removewithfilter = (arr) => {
  let outputArray = arr.filter(function (v, i, self) {
    // It returns the index of the first
    // instance of each value
    return i == self.indexOf(v);
  });

  return outputArray;
};

//filter sheet results and save to redux state
export const filterSheetResults = (appliedfilters) => {
  const sheetResults = store.getState().userSelections.campaign_sheet_records;
  let filteredRecords = [];
  sheetResults.forEach((sheetRecord) => {
    if (appliedfilters.includes(sheetRecord.description)) {
      filteredRecords.push(sheetRecord);
    }
  });

  store.dispatch(
    updateStateData({
      filtered_sheet_records: filteredRecords,
      filter_applied: true,
    })
  );
};

// filter product adlevels
export const filterAdlevelResults = (appliedfilters) => {
  const adlevelResults = store.getState().userSelections.created_adlevels;
  let filteredRecords = [];
  adlevelResults.forEach((adlevelRecord, key) => {
    if (appliedfilters.includes(adlevelRecord.nameAdLevel)) {
      filteredRecords = [...filteredRecords, { ...adlevelRecord, actual_key: key }];
    }
  });

  store.dispatch(
    updateStateData({
      filtered_adlevels: filteredRecords,
      adlevel_filter_applied: true,
    })
  );
};

//to uncheck all the checkboxes
export const checkUncheckAllCheckboxes = (action = true) => {
  let cbs = document.getElementsByTagName("input");

  for (let i = 0; i < cbs.length; i++) {
    if (cbs[i].type == "checkbox") {
      cbs[i].checked = action;
    }
  }
};

//replace umlaout characters with english words
export const replaceUmlaouts = (str) => {
  const umlautMap = {
    "\u00dc": "UE",
    "\u00c4": "AE",
    "\u00d6": "OE",
    "\u00fc": "ue",
    "\u00e4": "ae",
    "\u00f6": "oe",
    "\u00df": "ss",
  };
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(new RegExp("[" + Object.keys(umlautMap).join("|") + "]", "g"), (a) => umlautMap[a]);
};

//to reset everything related to campaign sheet and campaigns
export const resetCampaignSheetData = () => {
  store.dispatch(
    deleteItems([
      "campaign_sheet_records",
      "filter_applied",
      "sheet_filter_keywords",
      "filtered_sheet_records",
      "created_adlevels",
      "campaign_detail",
      "new_campaign_data",
      // "campaign_list",
      // "campaign_list_filters",
      // "filtered_campaign_list",
      // "campaign_list_filter_applied",
    ])
  );
};

// to reset report share data
export const resetReportShareData = () => {
  store.dispatch(deleteItems(["report_share_data"]));
};

export const restructure_product_information = (productInfoResponse) => {
  let restructuredData = [];
  //traverse all the records from campaignData
  productInfoResponse.forEach((product) => {
    if (product.description !== "") {
      let restructureProductInfo = {
        description: product.description,
        gtin: product.gtin,
        nameAdLevel: product.name_adlevel,
        numberAdLevel: product.number_adlevel,
        gtinBundle: product.gtin_bundle,
        bundleSize: product.bundle_size,
        nameCompany: product.name_company,
        campaign: product.campaign.id,
        id: product.id,
      };
      restructuredData.push(restructureProductInfo);
    }
  });
  //sort the data in ascending order
  // restructuredData = sortAdlevel(true, restructuredData);
  //save to redux
  store.dispatch(
    updateStateData({
      created_adlevels: restructuredData,
    })
  );

  return {
    restructuredData,
  };
};

export const fetch_adlevel_names = (productInfoResponse) => {
  let restructuredData = [];
  let checkForDuplicates = [];
  //traverse all the records from campaignData
  productInfoResponse.forEach((product) => {
    if (product.description !== "" && !checkForDuplicates.includes(product.nameAdLevel)) {
      checkForDuplicates.push(product.nameAdLevel);
      restructuredData.push({ value: product.nameAdLevel, label: product.nameAdLevel });
    }
  });

  //save to redux
  store.dispatch(
    updateStateData({
      adlevel_names: restructuredData,
    })
  );

  return {
    restructuredData,
  };
};

export const rangeOfNumbers = (counter) => {
  return Array.from({ length: counter }, (e, i) => i);
};

// rearrange redux state when adlevels are updated with or without filters
export const reArrangeAdlevelRedux = (updatedAdlevelRecords, originalRecords) => {
  const sheetDetails = store.getState().userSelections;
  if (typeof sheetDetails.adlevel_filter_applied !== "undefined" && sheetDetails.adlevel_filter_applied === true) {
    store.dispatch(
      updateStateData({
        filtered_adlevels: updatedAdlevelRecords,
      })
    );
    //update original records as well otherwise the changes won't reflect on filter reset
    if (typeof originalRecords !== "undefined" && originalRecords.length !== 0) {
      store.dispatch(
        updateStateData({
          created_adlevels: originalRecords,
        })
      );
    }
  } else {
    store.dispatch(
      updateStateData({
        created_adlevels: updatedAdlevelRecords,
      })
    );
  }
};

//to sort the adlevel records according to adlevel number.
export const sortAdlevel = (sortMeasureType, listItems) => {
  let adlevelArray = listItems;
  adlevelArray = adlevelArray.slice().sort((next, prev) => {
    if (sortMeasureType === true) {
      return next.numberAdLevel - prev.numberAdLevel;
    } else {
      return prev.numberAdLevel - next.numberAdLevel;
    }
  });

  //update redux state
  reArrangeAdlevelRedux(adlevelArray, []);
};

export const fetchGtinList = () => {
  let gtinList = [];
  let gtinDescription = [];
  let checkForDuplicates = [];
  const adlevelRecords = store.getState().userSelections.created_adlevels;
  //traverse all the records from campaignData
  if (adlevelRecords.length !== 0) {
    adlevelRecords.forEach((adlevel) => {
      if (adlevel.gtin !== "" && !checkForDuplicates.includes(adlevel.gtin)) {
        checkForDuplicates.push(adlevel.gtin);
        gtinList.push({ value: adlevel.gtin, label: adlevel.description + " - " + adlevel.gtin });
      }
    });
  }

  //save to redux
  store.dispatch(
    updateStateData({
      gtin_list: gtinList,
    })
  );

  return {
    gtinList,
    gtinDescription,
  };
};
